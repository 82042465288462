import React from 'react'
// import { Link } from 'gatsby'

import Layout from '../components/Layout/Layout'
import SEO from '../components/Core/seo'
import { Container, Row, Col } from 'react-bootstrap'

import DashboardShot from '../images/dashboard-shot.png'
import PlaystoreIcon from '../images/google-play.png'

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Home" />
      <div className="app">
        {/* Hero Section */}
        <section id="hero" className="hero">
          <Container>
            <Row className="justify-content-center">
              <Col md="7">
                <div className="hero__text text-center">
                  <h1 className="hero__title">
                    A Smarter Approach to Modern Retail Insights
                  </h1>
                  <p className="hero__subtitle mb-4">
                    Shelfie offers a smarter way to track insights from modern retail stores. Know assortment data, peer into visibility of shelves and secondary displays, track stock and the rate of sales and be presented expiry reports per retail location.
                  </p>
                </div>
              </Col>
              <Col md="9">
                <div className="hero__image text-center mb-5">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.suplias.weshelfie"
                  >
                    <img style={{width:"150px"}} src={PlaystoreIcon} alt="Playstore Icon" />
                  </a>
                </div>
              </Col>
              <Col md="9">
                <div className="hero__image">
                  <img className="image-100" src={DashboardShot} alt="" />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </Layout>
  )
}

export default IndexPage
